//store.js
import { configureStore, createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { db } from './firebase';



const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        },
    },
});
const userReducer = userSlice.reducer;

const shortLinkSlice = createSlice({
    name: 'shortLink',
    initialState: {
        arrayShortLinks: ['admin'],
        serverArrayLinks: []
    },
    reducers: {
        addShortLink: (state, action) => {
            const { shortName, link, password, uid } = action.payload;
            state[uid] = state[uid] || {};

            let data = {
                link,
                password,
                uploaded: false
            }

            if (!state.arrayShortLinks.includes(shortName)) {
                state.arrayShortLinks.push(shortName);
                state[uid][shortName] = data;
            }
        },
        editShortLink: (state, action) => {
            const { shortName, link, password, uid } = action.payload;
            if (state[uid] === undefined) {
                state[uid] = {
                    arrayShortLinks: []
                };
            }
            state[uid][shortName] = {
                link,
                password,
                uploaded: false
            };
            if (!state.arrayShortLinks.includes(shortName)) {
                state.arrayShortLinks.push(shortName);
            }
        },
        deleteShortLink: (state, action) => {
            const { shortName, uid } = action.payload;
            if (state[uid] !== undefined && state[uid][shortName] !== undefined) {
                state[uid][shortName].uploaded = false;
                state[uid][shortName].toBeDeleted = true;
                state.arrayShortLinks = state.arrayShortLinks.filter((item) => item !== shortName);
            }
        },
        restoreShortLink: (state, action) => {
            const { shortName, uid } = action.payload;
            if (state[uid] !== undefined && state[uid][shortName] !== undefined) {
                state[uid][shortName].uploaded = false;
                delete state[uid][shortName].toBeDeleted;
                state.arrayShortLinks.push(shortName);
            }
        },
        uploadedToFirestore: (state, action) => {
            const { updatedKeys, uid, arrayShortLinks, serverUserData } = action.payload;
            state.serverArrayLinks = arrayShortLinks;
            state.fireStoreTime = new Date().getTime();
            if (state[uid] !== undefined) {
                updatedKeys.forEach((key) => {
                    if (state[uid][key] !== undefined) {
                        state[uid][key].uploaded = true;
                        if (state[uid][key].toBeDeleted) {
                            delete state[uid][key];
                            state.arrayShortLinks = state.arrayShortLinks.filter((item) => item !== key);
                        }
                    }
                });
            }
            state[uid] = {
                ...state[uid],
                ...serverUserData
            }
        },
        setShortLinkError: (state, action) => {
            const { shortName, uid, error } = action.payload;
            if (state[uid] !== undefined && state[uid][shortName] !== undefined) {
                state[uid][shortName].error = error;
                state[uid][shortName].toBeDeleted = true;
            }
        }
    }
});

const shortReducer = shortLinkSlice.reducer;


const quotesSlice = createSlice({
    name: 'quotes',
    initialState: {},
    reducers: {
        addQuote: (state, action) => {
            const { quote, themes, uid } = action.payload;
            if (state[uid] === undefined) {
                state[uid] = { themes: [], quotes: {} };
            }

            for (let theme of themes) {
                if (!state[uid].themes.includes(theme)) {
                    state[uid].themes.push(theme);
                }
            }
            const createdAt = new Date().getTime().toString();
            let data = {
                quote: [quote],
                themes,
                likes: 0,
                isDeleted: false,
                isArchived: false,
                uploaded: false
            };
            state[uid].quotes[createdAt] = data;
        },
        editQuote: (state, action) => {
            const { quote, themes, uid, quoteKey, saveVersion } = action.payload;
            if (state[uid] === undefined) {
                state[uid] = { themes: [], quotes: {} };
            }
            if (state[uid].quotes[quoteKey] === undefined) {
                state[uid].quotes[quoteKey] = {};
            }
            for (let theme of themes) {
                if (!state[uid].themes.includes(theme)) {
                    state[uid].themes.push(theme);
                }
            }
            if (saveVersion) {
                state[uid].quotes[quoteKey].quote.push(quote);
            }
            else {
                state[uid].quotes[quoteKey].quote[state[uid].quotes[quoteKey].quote.length - 1] = quote;
            }
            state[uid].quotes[quoteKey].themes = themes;
            if (state[uid].quotes[quoteKey].likes === undefined) {
                state[uid].quotes[quoteKey].likes = 0;
            }
            if (state[uid].quotes[quoteKey].isDeleted === undefined) {
                state[uid].quotes[quoteKey].isDeleted = false;
            }
            if (state[uid].quotes[quoteKey].isArchived === undefined) {
                state[uid].quotes[quoteKey].isArchived = false;
            }
            state[uid].quotes[quoteKey].uploaded = false;
        },
        deleteQuote: (state, action) => {
            const { uid, quoteKey } = action.payload;
            if (state[uid] !== undefined) {
                state[uid].quotes[quoteKey].isDeleted = true;
                state[uid].quotes[quoteKey].uploaded = false;
            }
        },
        archiveQuote: (state, action) => {
            const { uid, quoteKey } = action.payload;
            if (state[uid] !== undefined) {
                state[uid].quotes[quoteKey].isArchived = true;
                state[uid].quotes[quoteKey].uploaded = false;
            }
        },
        likeQuote: (state, action) => {
            const { uid, quoteKey } = action.payload;
            if (state[uid] !== undefined) {
                state[uid].quotes[quoteKey].likes++;
                state[uid].quotes[quoteKey].uploaded = false;
            }
        },
        restoreQuote: (state, action) => {
            const { uid, quoteKey } = action.payload;
            if (state[uid] !== undefined) {
                state[uid].quotes[quoteKey].isDeleted = false;
                state[uid].quotes[quoteKey].uploaded = false;
            }
        },
        unArchiveQuote: (state, action) => {
            const { uid, quoteKey } = action.payload;
            if (state[uid] !== undefined) {
                state[uid].quotes[quoteKey].isArchived = false;
                state[uid].quotes[quoteKey].uploaded = false;
            }
        },
        uploadedQuoteFirestore: (state, action) => {
            const { updatedKeys, uid, serverQuotes, themes } = action.payload;
            state[uid] = {
                themes,
                quotes: {
                    ...state[uid]?.quotes,
                    ...serverQuotes
                },
                fireStoreTime: new Date().getTime()
            }
            updatedKeys.forEach((key) => {
                if (state[uid]?.quotes[key] !== undefined) {
                    state[uid].quotes[key].uploaded = true;
                }
            });
        }

        // setQuoteError: (state, action) => {
        //     const { shortName, uid, error } = action.payload;
        //     if (state[uid] !== undefined && state[uid][shortName] !== undefined) {
        //         state[uid][shortName].error = error;
        //         state[uid][shortName].toBeDeleted = true;
        //     }
        // }
    }
});

const quotesReducer = quotesSlice.reducer;

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        console.error('Error loading state from local storage:', error);
        return undefined;
    }
};

const localStorageMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
    return result;
};

const store = configureStore({
    reducer: {
        user: userReducer,
        shortLink: shortReducer,
        quotes: quotesReducer
    },
    preloadedState: loadStateFromLocalStorage(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(localStorageMiddleware),
});

export const { setUser } = userSlice.actions;
export const { addShortLink, editShortLink, deleteShortLink, uploadedToFirestore, setShortLinkError, restoreShortLink } = shortLinkSlice.actions;
export const { addQuote, editQuote, deleteQuote, archiveQuote, likeQuote, restoreQuote, unArchiveQuote, uploadedQuoteFirestore } = quotesSlice.actions;
export default store;
