import React, { useState, useEffect, useRef } from 'react';

export const Accordion = ({ id, className, children, name }) => {
    const [isActive, setIsActive] = useState(false);
    const panelRef = useRef(null);

    useEffect(() => {
        if (panelRef.current) {
            panelRef.current.style.maxHeight = isActive ? `${panelRef.current.scrollHeight}px` : null;
        }
    }, [isActive]);

    const toggleAccordion = () => {
        setIsActive(!isActive);
    };

    return (
        <div>
            <button
                id={id}
                className={`accordion ${isActive ? 'active' : ''} ${className}`}
                onClick={toggleAccordion}
            >
                {name}
            </button>
            <div className='panel' ref={panelRef}>
                {children}
            </div>
        </div>
    );
};