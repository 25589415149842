import { useState, useEffect } from 'react';
import { collection, doc, getDocs, Timestamp, getDoc, onSnapshot, query, where, addDoc, setDoc, updateDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { db, storage } from './firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { addShortLink, editShortLink, deleteShortLink, uploadedToFirestore, setShortLinkError, uploadedQuoteFirestore } from './store';

export const useShortLinkUpdateFirebase = () => {
    const [uploadError, setUploadError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const shortLinkDB = useSelector((state) => state.shortLink);

    const uploadData = async (uidPara) => {
        try {
            let uid = uidPara;
            if (uidPara === undefined) {
                uid = user.uid;
            }
            const shortLinkDocRef = doc(db, 'shortlink', 'data');
            const shortLinkData = shortLinkDB[uid];
            const shortLinkMasterDoc = await getDoc(shortLinkDocRef);
            const existingData = shortLinkMasterDoc.exists() ? shortLinkMasterDoc.data() : {
                arrayShortLinks: ['admin', 'quote']
            };
            const updatedData = {
                arrayShortLinks: existingData.arrayShortLinks || ['admin'],
                [uid]: existingData[uid] || {}
            };
            let updatedKeys = [];

            if (shortLinkData !== undefined && shortLinkData !== null) {
                Object.entries(shortLinkData).forEach(([shortName, data]) => {
                    if (data.uploaded === false) {
                        let Password = data.password?.trim();
                        Password = Password === '' ? null : Password;
                        const keyUpload = {
                            link: data.link,
                            password: Password
                        }
                        if (data.toBeDeleted === true) {
                            if (!data.error) {
                                updatedData.arrayShortLinks = updatedData.arrayShortLinks.filter((item) => item !== shortName);
                            }
                            if (updatedData[uid] && updatedData[uid][shortName] !== undefined) {
                                delete updatedData[uid][shortName];
                            }
                            updatedKeys.push(shortName);
                        } else {
                            if (updatedData[uid] && updatedData[uid][shortName] !== undefined) {
                                updatedData[uid][shortName] = keyUpload;
                                if (!updatedData.arrayShortLinks.includes(shortName)) {
                                    updatedData.arrayShortLinks.push(shortName);
                                }
                                updatedKeys.push(shortName);
                            } else if (!updatedData.arrayShortLinks.includes(shortName)) {
                                updatedData.arrayShortLinks.push(shortName);
                                updatedData[uid][shortName] = keyUpload;
                                updatedKeys.push(shortName);
                            } else {
                                dispatch(setShortLinkError({ shortName, uid, error: 'Short link already exists' }));
                            }
                        }
                    }
                });

                await setDoc(shortLinkDocRef, updatedData, { merge: true });
            }
            dispatch(uploadedToFirestore({ updatedKeys, uid, arrayShortLinks: updatedData.arrayShortLinks, serverUserData: updatedData[uid] }));
            setUploadError(null);
        } catch (err) {
            setUploadError(err);
            console.error(err);
        } finally {
            setIsUploading(false);
        }
    };

    return [uploadData, isUploading, uploadError];
};

export const useQuoteUpdateFirebase = () => {
    const [uploadError, setUploadError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const quoteDB = useSelector((state) => state.quotes);

    const uploadData = async (uidPara) => {
        try {
            let uid = uidPara;
            if (uidPara === undefined) {
                uid = user.uid;
            }
            const quoteDocRef = doc(db, 'quotes', uid);
            const quoteDocSnapshot = await getDoc(quoteDocRef);
            const quoteData = quoteDB[uid] || {
                themes: [],
                quotes: {}
            };
            const existBool = quoteDocSnapshot.exists();
            const existingData = existBool ? quoteDocSnapshot.data() : {
                themes: [],
                quotes: {}
            };
            let updatedData = {
                themes: [...existingData?.themes, ...quoteData?.themes],
                quotes: existingData.quotes || {}
            };
            updatedData.themes = [...new Set(updatedData.themes)];
            updatedData.themes = updatedData.themes.map((theme) => theme.trim()).filter((theme) => theme !== '');
            let updatedKeys = [];


            if (quoteData !== undefined && quoteData !== null) {
                Object.entries(quoteData.quotes).forEach(([quoteKey, data]) => {
                    if (data.uploaded === false) {
                        const { quote, themes, likes, isDeleted, isArchived } = data;
                        const quoteUpload = {
                            quote,
                            themes,
                            likes,
                            isDeleted,
                            isArchived
                        }
                        if (updatedData?.quotes[quoteKey] !== undefined) {
                            updatedData.quotes[quoteKey] = quoteUpload;
                            updatedKeys.push(quoteKey);
                        } else {
                            updatedData.quotes[quoteKey] = quoteUpload;
                            updatedKeys.push(quoteKey);
                        }
                    }
                });
                if (updatedKeys.length > 0) {
                    if (existBool) {
                        await setDoc(quoteDocRef, updatedData, { merge: true });
                    } else {
                        await setDoc(quoteDocRef, updatedData);
                    }
                }
            }
            dispatch(uploadedQuoteFirestore({ updatedKeys, uid, themes: updatedData.themes, serverQuotes: updatedData.quotes }));
            setUploadError(null);
        } catch (err) {
            setUploadError(err);
            console.error(err);
        } finally {
            setIsUploading(false);
        }

    }
    return [uploadData, isUploading, uploadError];
};

export const useAuditDoc = () => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBills = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const salesRef = doc(db, "Audit", "audit1");
                const querySnapshot = await getDoc(salesRef);
                const docRef = doc(db, "stock", 'data');
                const documentSnapshot = await getDoc(docRef);
                var InpData, StockData;
                if (querySnapshot.exists()) {
                    InpData = querySnapshot.data();
                } else {
                    setError("No Audit document exists!");
                    setDocuments([]);
                }
                if (documentSnapshot.exists()) {
                    StockData = documentSnapshot.data();
                } else {
                    console.log('Document does not exist');
                    setError("No Stock Data Found");
                }
                var docs = [];
                var inpDataKeys = Object.keys(InpData);
                var stockDataKeys = Object.keys(StockData);
                var arrayKeys = inpDataKeys.concat(stockDataKeys);

                arrayKeys.forEach((key) => {
                    if (key in InpData && key in StockData) {
                        if (InpData[key].qty != StockData[key].SOH) {
                            docs.push({
                                id: key,
                                ...InpData[key],
                                ...StockData[key]
                            });
                        }
                    } else if (key in InpData) {
                        docs.push({
                            id: key,
                            ...InpData[key],
                            ...{ SOH: 0, MRP: 0 }
                        });
                    } else if (key in StockData) {
                        docs.push({
                            id: key,
                            ...StockData[key],
                            ...{ qty: 0, shelf: "" }
                        });
                    }
                });
                setDocuments(docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err.message);
                console.error(err);
            }
        };

        fetchBills();
    }, []);

    return [documents, isLoading, error];
};

export const useSubmitAudit = () => {
    const [feterror, setFet] = useState(null);
    const [isCreating, setIsCreating] = useState(false);

    const submitAudit = async (input, shelf) => {
        try {
            setIsCreating(true);

            const auditDocRef = doc(db, 'Audit', 'audit1');
            const auditDocSnapshot = await getDoc(auditDocRef);

            if (auditDocSnapshot.exists()) {
                const auditData = auditDocSnapshot.data();
                const newData = { ...auditData };

                input.forEach((item) => {
                    const { EAN, QUANTITY } = item;
                    if (EAN in auditData) {
                        newData[EAN].qty += QUANTITY;
                    } else {
                        newData[EAN] = { shelf: shelf, qty: QUANTITY };
                    }
                });

                await setDoc(auditDocRef, newData);
                setFet('Report submitted successfully!');
            } else {
                const newData = {};

                input.forEach((item) => {
                    const { EAN, QUANTITY } = item;
                    newData[EAN] = { shelf: shelf, qty: QUANTITY };
                });

                await setDoc(auditDocRef, newData);
                setFet('Audit document does not exist. So, Create one!');
            }
        } catch (error) {
            console.error('Error submitting report:', error);
            setFet(error.message);
        } finally {
            setIsCreating(false); // Set isCreating to false when audit submission is complete or encounters an error
        }
    };

    return [submitAudit, isCreating, feterror]; // Include isCreating in the hook export
};

export const useCreateDailySales = () => {
    const [feterror, setFet] = useState(null);
    const [isCreating, setIsCreating] = useState(false);

    const submitAudit = async (input, day, month, year) => {
        try {
            setIsCreating(true);
            const DocId = `${month}-${year}`;
            const auditDocRef = doc(db, 'sales', DocId);
            const auditDocSnapshot = await getDoc(auditDocRef);

            if (auditDocSnapshot.exists()) {
                const auditData = auditDocSnapshot.data();
                const newData = { ...auditData };
                newData[day] = input;
                await setDoc(auditDocRef, newData);
                setFet('Report submitted successfully!');
            } else {
                const stuc = {
                    byId: null,
                    innoviti: 0,
                    creditNote: 0,
                    AILPrepaid: 0,
                    issueStoreCredit: 0,
                    AILCOD: 0,
                    offlineCard: 0,
                    cash: 0,
                    innovitiUPI: 0,
                    AILRefund: 0,
                    UPI: 0,
                    description: null,
                    date: 0,
                    time: 0,
                    isDeleted: 0,
                    sbi: 0,
                    idfc: 0,
                    icici: 0,
                };
                const daysInMonth = new Date(year, month, 0).getDate();
                const newData = {};
                for (let i = 1; i <= daysInMonth; i++) {
                    const day = i.toString().padStart(2, '0');
                    newData[day] = { ...stuc, date: `${year}-${month}-${day}` };

                }
                newData[day] = input;
                await setDoc(auditDocRef, newData);
                setFet('Audit document does not exist');
            }
        } catch (error) {
            console.error('Error submitting report:', error);
            setFet(error.message);
        } finally {
            setIsCreating(false); // Set isCreating to false when audit submission is complete or encounters an error
        }
    };

    return [submitAudit, isCreating, feterror];
};

export const useSummarySystem = () => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const salesRef = collection(db, "company");
        const q = query(salesRef, where("isDeleted", "==", false));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setIsLoading(true);
            setError(null);
            try {
                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDocuments(docs);
                console.log("Company:", docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
                console.error(err);
            }
        });

        return () => unsubscribe();
    }, []);

    return [documents, isLoading, error];
};


export const useBilled = () => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBills = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const salesRef = collection(db, "billed");
                const querySnapshot = await getDocs(
                    query(
                        salesRef,
                        where("isBilled", "==", false)
                    )
                );

                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDocuments(docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
                console.error(err);
            }
        };

        fetchBills();
    }, []);

    return [documents, isLoading, error];
};


export const useBillsSettle = (offerType) => {
    /*Offer Type can be:
    B1G1: Buy 1-Get 1
    B2G1: Buy 2-Get 1
    B2G2: Buy 2-Get 2
    B3G2: Buy 3-Get 2
    B3G1: Buy 3-Get 1
    B3G3: Buy 3-Get 3
    F50: Flat 50%
    F40: Flat 40%
    F30: Flat 30%
    F20: Flat 20%
    F10: Flat 10%
    */
    const [item, setItems] = useState(null);
    const [saleTotal, setSaleTotal] = useState(0);
    const [billTotal, setBillTotal] = useState(0);
    const [docList, setDocList] = useState([]);

    useEffect(() => {
        const fetchBills = async () => {
            var offerCatBG;
            var offerPairCount;
            var offerRemainderCount;
            var offerPercent;
            console.log(offerType);
            switch (offerType) {
                case "B1G1":
                    offerCatBG = true;
                    offerPairCount = 2;
                    offerRemainderCount = 1;
                    break;
                case "B2G1":
                    offerCatBG = true;
                    offerPairCount = 3;
                    offerRemainderCount = 2;
                    break;
                case "B2G2":
                    offerCatBG = true;
                    offerPairCount = 4;
                    offerRemainderCount = 2;
                    break;
                case "B3G1":
                    offerCatBG = true;
                    offerPairCount = 4;
                    offerRemainderCount = 1;
                    break;
                case "B3G2":
                    offerCatBG = true;
                    offerPairCount = 5;
                    offerRemainderCount = 2;
                    break;
                case "B3G3":
                    offerCatBG = true;
                    offerPairCount = 6;
                    offerRemainderCount = 3;
                    break;
                case "F50":
                    offerCatBG = false;
                    offerPercent = 50;
                    break;
                case "F40":
                    offerCatBG = false;
                    offerPercent = 40;
                    break;
                case "F30":
                    offerCatBG = false;
                    offerPercent = 30;
                    break;
                case "F20":
                    offerCatBG = false;
                    offerPercent = 20;
                    break;
                case "F10":
                    offerCatBG = false;
                    offerPercent = 10;
                    break;
                default:
                    offerCatBG = false;
                    offerPercent = 0;
            }
            const billsRef = collection(db, "bills");
            const q = query(billsRef, where("isBilled", "==", false));
            const querySnapshot = await getDocs(q);
            let combinedItems = [];
            let docList = [];
            querySnapshot.forEach((doc) => {
                const billData = doc.data();
                const docId = doc.id;
                const { items, percent } = billData;
                const percentage = parseInt(percent);
                docList.push(docId);
                items.forEach((item) => {
                    const { mrp, ean } = item;
                    combinedItems.push({ mrp, ean, docId, percentage });
                });
            });
            setDocList(docList);
            combinedItems.sort((a, b) => parseFloat(b.mrp) - parseFloat(a.mrp));

            const numberOfItems = combinedItems.length;
            let varSaleTotal = 0; // Total amount to be paid by customer
            let varBillTotal = 0; // Total amount of the bill

            if (offerCatBG) {
                for (let i = 0; i < numberOfItems; i++) {
                    var itemSale = parseFloat(Math.ceil((combinedItems[i].mrp / 100) * (100 - combinedItems[i].percentage)));
                    combinedItems[i].paid = itemSale;
                    varSaleTotal += itemSale;
                    combinedItems[i].slot = Math.floor(i / offerPairCount) + 1;
                    if (i % offerPairCount < (offerPairCount - offerRemainderCount)) {
                        combinedItems[i].toBeBilled = true;
                        varBillTotal += parseFloat(combinedItems[i].mrp);
                    } else {
                        combinedItems[i].toBeBilled = false;
                    }
                }
            } else {
                for (let i = 0; i < numberOfItems; i++) {
                    var itemSale = parseFloat(Math.ceil((combinedItems[i].mrp / 100) * (100 - combinedItems[i].percentage)));
                    combinedItems[i].toBeBilled = true;
                    combinedItems[i].paid = itemSale;
                    varSaleTotal += itemSale;
                    varBillTotal += parseFloat(combinedItems[i].mrp);
                    combinedItems[i].slot = Math.floor(i / 6) + 1;
                }
                varBillTotal = parseFloat(varBillTotal - (varBillTotal * offerPercent / 100));
            }
            setSaleTotal(varSaleTotal);
            setItems(combinedItems);
            setBillTotal(varBillTotal);
        };

        fetchBills();
    }, [db]);

    return [item, saleTotal, billTotal, docList];
};


export const useSettleBilled = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);

    const settleUpload = async (data) => {
        setIsUploading(true);
        setError(null);
        setUploadProgress(0);
        let newBilledDocRef, newBilledDocRefsave;

        try {
            const billedCollectionRef = collection(db, "billed");
            const billedData = {
                byId: data.byId,
                items: data.items,
                docList: data.docList,
                saleAmt: data.sale,
                billAmt: data.bill,
                saveAmt: data.saved,
                time: data.time,
                offer: data.offer,
                isBilled: false
            };

            newBilledDocRef = await addDoc(billedCollectionRef, billedData);
            var save = data.sale - data.bill;
            const saveBill = {
                type: "billSave",
                byId: data.byId,
                amount: parseInt(save),
                time: Timestamp.fromDate(new Date()),
                isDeleted: false
            };
            const billedCollectionRefsave = collection(db, "company");

            newBilledDocRefsave = await addDoc(billedCollectionRefsave, saveBill);

            var batch = writeBatch(db);
            const batchSize = 500;
            const totalDocuments = data.docList.length;
            let documentsProcessed = 0;

            for (let i = 0; i < data.docList.length; i++) {
                const docId = data.docList[i];
                const docRef = doc(db, "bills", docId);
                batch.update(docRef, { isBilled: true });
                documentsProcessed++;

                if (documentsProcessed % batchSize === 0) {
                    await batch.commit();
                    setUploadProgress((documentsProcessed / totalDocuments) * 100);
                    batch = writeBatch(db);
                }
            }

            await batch.commit();

            setUploadProgress(100);
            setIsUploading(false);
        } catch (err) {
            setIsUploading(false);
            setError(err);
            console.error(err);
            try {
                if (newBilledDocRef) {
                    await deleteDoc(doc(db, "billed", newBilledDocRef.id));
                }
                if (newBilledDocRefsave) {
                    await deleteDoc(doc(db, "company", newBilledDocRefsave));
                }

                var batch = writeBatch(db);
                const batchSize = 500;
                const totalDocuments = data.docList.length;
                let documentsProcessed = 0;

                for (let i = 0; i < data.docList.length; i++) {
                    const docId = data.docList[i];
                    const docRef = doc(db, "bills", docId);
                    batch.update(docRef, { isBilled: false });
                    documentsProcessed++;

                    if (documentsProcessed % batchSize === 0) {
                        await batch.commit();
                        setUploadProgress((documentsProcessed / totalDocuments) * 100);
                        batch = writeBatch(db);
                    }
                }
                await batch.commit();
            } catch (err) {
                const inpd = error + "\n\nHigh Alert: report developer & Audit the bills\n\n" + err;
                setError(inpd);
                console.error(inpd);
            }
        }
    };

    return [settleUpload, isUploading, error, uploadProgress];
};

export const useBulkUpload = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);

    const newBulkUpload = async (collectionName, dataArray) => {
        setIsUploading(true);
        setError(null);
        setUploadProgress(0);

        try {
            const stockDocRef = doc(db, collectionName, 'data');
            const stockDoc = await getDoc(stockDocRef);
            const existingData = stockDoc.exists() ? stockDoc.data() : {};

            const updatedData = { ...existingData };

            dataArray.forEach((item) => {
                if (item.EAN) {
                    updatedData[item.EAN] = {
                        DESCRIPTION: item.DESCRIPTION,
                        MRP: item.MRP,
                        SIZE: item.SIZE,
                        SOH: item.SOH,
                        STYLE_CODE: item.STYLE_CODE,
                    };
                }
            });

            await setDoc(stockDocRef, updatedData);

            setUploadProgress(100);
            setIsUploading(false);
        } catch (err) {
            setIsUploading(false);
            setError(err);
            console.error(err);
        }
    };

    return [newBulkUpload, isUploading, error, uploadProgress];
};

export const useOldBulkUpload = () => {
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);

    const bulkUpload = async (collectionName, data) => {
        setIsUploading(true);
        setError(null);
        setUploadProgress(0);

        try {
            var batch = writeBatch(db);
            const batchSize = 500; // Number of documents to write per batch
            const totalDocuments = data.length;
            let documentsProcessed = 0;

            for (let i = 0; i < data.length; i++) {
                const docData = data[i];
                if (docData.EAN) {
                    const docRef = doc(db, collectionName, docData.EAN);
                    batch.set(docRef, docData);

                    if ((i + 1) % batchSize === 0) {
                        await batch.commit();
                        documentsProcessed += batchSize;
                        setUploadProgress((documentsProcessed / totalDocuments) * 100);
                        batch = writeBatch(db);
                    }
                }
            }

            await batch.commit();
            documentsProcessed += data.length % batchSize;
            setUploadProgress((documentsProcessed / totalDocuments) * 100);
            setIsUploading(false);
        } catch (err) {
            setIsUploading(false);
            setError(err);
            console.error(err);
        }
    };

    return [bulkUpload, isUploading, error, uploadProgress];
};
export const useFileUpload = () => {
    const [downloadUrl, setDownloadUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const uploadFile = async (file) => {
        setIsLoading(true);

        const storageRef = ref(storage, 'files/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                console.error('Error uploading file: ', error);
                setIsLoading(false);

                console.error(error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setDownloadUrl(downloadURL);
                    setIsLoading(false);
                    console.log('File available at', downloadURL);
                });
            }
        );

    };

    return { uploadFile, downloadUrl, uploadProgress, isLoading };
};

export const useCreateDocument = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const createDocument = async (collectionName, data, TransId) => {
        setIsLoading(true);
        setError(null);

        try {
            if (TransId === undefined) {
                const docRef = await addDoc(collection(db, collectionName), data);
                setIsLoading(false);
                return docRef.id;
            } else {
                const docRef = doc(db, collectionName, TransId);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists()) {
                    throw new Error("Document already exists");
                }

                await setDoc(docRef, data);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            setError(err);
            console.error(err);
        }
    };

    return [createDocument, isLoading, error];
};

export const useReadSummary = () => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSummary = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const summaryRef = collection(db, 'summary');
                const querySnapshot = await getDocs(summaryRef);
                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                console.log(docs);
                setDocuments(docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
                console.error(err);
            }
        };

        fetchSummary();
    }, []);

    return [documents, isLoading, error];
};

export const useBills = () => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBills = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const salesRef = collection(db, "bills");
                const querySnapshot = await getDocs(
                    query(
                        salesRef,
                        where("isDeleted", "==", false),
                        where("isBilled", "==", false)
                    )
                );

                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDocuments(docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
                console.error(err);
            }
        };

        fetchBills();
    }, []);

    return [documents, isLoading, error];
};

export const useReadDocuments = (collectionName, key, value) => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const salesRef = collection(db, collectionName);
        const q = query(salesRef, where(key, "==", value), where("isDeleted", "==", false));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setIsLoading(true);
            setError(null);

            try {
                const docs = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setDocuments(docs);
                console.log("Documents:", docs);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err);
                console.error(err);
            }
        });

        return () => unsubscribe();
    }, [collectionName, key, value]);

    return [documents, isLoading, error];
};

export const useReadDailySales = (year, month) => {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const salesRef = doc(db, "sales", `${month}-${year}`);
        const unsubscribe = onSnapshot(salesRef, (snapshot) => {
            setIsLoading(true);
            setError(null);

            if (snapshot.exists()) {
                const InpData = snapshot.data();
                const docs = Object.keys(InpData).map((key) => ({
                    id: key,
                    ...InpData[key],
                }));
                setDocuments(docs);
            } else {
                setError("No such document!");
                setDocuments([]);
            }

            setIsLoading(false);
        });

        return () => unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
    }, [year, month]);

    return [documents, isLoading, error];
};

export const useUpdateDocument = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateDocument = async (collectionName, documentId, data) => {
        setIsLoading(true);
        setError(null);

        try {
            const docRef = doc(db, collectionName, documentId);
            await updateDoc(docRef, data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
            console.error(err);
        }
    };

    return [updateDocument, isLoading, error];
};

export const useTempDeleteDocument = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateDocument = async (collectionName, documentId) => {
        setIsLoading(true);
        setError(null);
        try {
            const docRef = doc(db, collectionName, documentId);
            const data = {
                isDeleted: true
            };
            await setDoc(docRef, data, { merge: true });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
            console.error(err);
        }
    };

    return [updateDocument, isLoading, error];
};

export const useDeleteDocument = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteDocument = async (collectionName, documentId) => {
        setIsLoading(true);
        setError(null);

        try {
            const docRef = doc(db, collectionName, documentId);
            await deleteDoc(docRef);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setError(err);
            console.error(err);
        }
    };

    return [deleteDocument, isLoading, error];
};