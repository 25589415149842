//short-page.js
import html2canvas from 'html2canvas';
import React, { useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { addQuote, archiveQuote, deleteQuote, editQuote, likeQuote, restoreQuote, unArchiveQuote } from '../../data/store';

import ImageIcon from '@mui/icons-material/Image';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import { useQuoteUpdateFirebase } from "../../data/firebaseHooks";


import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestoreIcon from '@mui/icons-material/Restore';
import ShareIcon from '@mui/icons-material/Share';
import SyncIcon from '@mui/icons-material/Sync';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Alert from '@mui/material/Alert';
import Autocomplete from "@mui/material/Autocomplete";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

/**
 *Quote or Philosophy Tracker: (Firestore)
- Collect and store favorite quotes or philosophical insights.
- Categorize quotes based on themes.
- Provide the option to share quotes on social media or with friends.
- It also has the quotation version as it store the old version of the quote in a array as it appends new one at the end.
data ={
    uid:{
        themes: [theme1, theme2, theme3],
        quotes: [
            {
                theme: [theme1, theme2],
                quote: "quote1",
                likes: 0,
                isDeleted: false,
                isArchived: false
            }
        ]
    }
}
 */
const QuoteForm = ({ onClose }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const quotesDB = useSelector((state) => state.quotes)?.[user.uid] || {
        themes: [],
        quotes: []
    };
    const [quote, setQuote] = useState('');
    const [themes, setThemes] = useState([]);
    const [options, setOptions] = useState([...quotesDB.themes]);
    const [textValue, settext] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (quote.trim() === '') {
            setErrorMessage('Quote cannot be empty');
            setDisabled(false);
            return false;
        } else if (themes.length < 1) {
            setErrorMessage('Themes cannot be empty');
            setDisabled(false);
            return false;
        } else {
            setErrorMessage('');
        }
        setDisabled(true);
        let data = {
            quote: quote.trim(),
            themes,
            uid: user.uid
        }
        dispatch(addQuote(data));
        setQuote('');
        setThemes([]);
        setDisabled(false);
        onClose();
    };

    const handleOptionType = (e) => {
        if (e.target.value !== null) {
            const lowerCaseValue = e.target.value.toLowerCase().trim();
            settext(lowerCaseValue);
            // settext(e.target.value);
        }
    }
    const handleOptionChange = (e) => {
        if (e.target.value.trim() !== '') {
            if (e.key === "Enter") {
                if (!options.includes(e.target.value)) {
                    setOptions(
                        (prev) => [...prev, e.target.value],
                    )
                } else {
                    if (!themes.includes(e.target.value)) {
                        setThemes(
                            (prev) => [...prev, e.target.value],
                        )
                        settext('');
                    }
                }
            }
        }
    }
    return (
        <Card sx={{ width: '90vw', maxWidth: '500px', margin: '0px', padding: '5px' }}>
            <CardContent>
                <h2>Add Quote</h2>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <TextField
                    multiline
                    margin="normal"
                    label="Quote"
                    id="outlined-required"
                    required
                    fullWidth
                    value={quote}
                    onChange={(e) => setQuote(e.target.value)}
                    autoComplete="off"
                />
                <Autocomplete
                    value={themes}
                    onChange={(e, selectedObject) => {
                        if (selectedObject !== null) {
                            setThemes(selectedObject);
                        }
                    }}
                    multiple
                    fullWidth
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={options}
                    noOptionsText="Press enter to add new theme"
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                value={textValue}
                                onChange={handleOptionType}
                                onKeyDown={handleOptionChange}
                                label="Themes"
                                placeholder="Add Themes"
                                pattern="[a-z0-9]+"
                            />
                        );
                    }}
                />
                <br />
                <Button type="button" variant="contained" color="primary" onClick={handleSubmit} disabled={disabled}>
                    Add Quote
                </Button>
            </CardContent>
        </Card>
    );
};

const QuoteCard = ({ quoteKey }) => {

    const quoteCardRef = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const quotesDB = useSelector((state) => state.quotes)?.[user.uid] || {
        themes: [],
        quotes: []
    };
    const [quote, setQuote] = useState(quotesDB.quotes[quoteKey]?.quote[quotesDB.quotes[quoteKey].quote.length - 1]);
    const [themes, setThemes] = useState([...quotesDB.quotes[quoteKey]?.themes]);
    const [options, setOptions] = useState([...quotesDB?.themes]);
    const [textValue, settext] = useState("");
    const [saveVersion, setSaveVersion] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [likeDone, setLikeDone] = useState(false);
    const [sharingImage, setSharingImage] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEditMode = () => {
        setEditMode(true);
    };
    const handleDelete = () => {
        dispatch(
            deleteQuote({
                uid: user.uid,
                quoteKey,
            })
        );
    };
    const handleArchive = () => {
        dispatch(
            archiveQuote({
                uid: user.uid,
                quoteKey,
            })
        );
    };
    const handleLike = () => {
        if (likeDone === false)
            dispatch(
                likeQuote({
                    uid: user.uid,
                    quoteKey,
                })
            );
        setLikeDone(true);
    };
    const handleRestore = () => {
        dispatch(
            restoreQuote({
                uid: user.uid,
                quoteKey,
            })
        );
    };
    const handleUnArchive = () => {
        dispatch(
            unArchiveQuote({
                uid: user.uid,
                quoteKey,
            })
        );
    };
    const handleCancelEdit = () => {
        setEditMode(false);
        setQuote(quotesDB.quotes[quoteKey].quote[quotesDB.quotes[quoteKey].quote.length - 1]);
        setThemes(quotesDB.quotes[quoteKey].themes);
        setSaveVersion(false);
        setDisabled(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        if (quote.trim() === '') {
            setErrorMessage('Quote cannot be empty');
            setDisabled(false);
            return false;
        } else if (themes.length < 1) {
            setErrorMessage('Themes cannot be empty');
            setDisabled(false);
            return false;
        } else {
            setErrorMessage('');
        }
        let data = {
            quote: quote.trim(),
            themes,
            uid: user.uid,
            quoteKey,
            saveVersion
        }
        dispatch(editQuote(data));
        setEditMode(false);
        setDisabled(false);
    };

    const handleOptionType = (e) => {
        if (e.target.value !== null) {
            const lowerCaseValue = e.target.value.toLowerCase().trim();
            settext(lowerCaseValue);
            // settext(e.target.value);
        }
    }
    const handleOptionChange = (e) => {
        if (e.target.value.trim() !== '') {
            if (e.key === "Enter") {
                if (!options.includes(e.target.value)) {
                    setOptions(
                        (prev) => [...prev, e.target.value],
                    )
                } else {
                    if (!themes.includes(e.target.value)) {
                        setThemes(
                            (prev) => [...prev, e.target.value],
                        )
                        settext('');
                    }
                }
            }
        }
    }

    const formattedText = (text) => {
        let formattedText = text;
        let textArray = text.split('\n');
        const newTextArray = [];
        for (let i = 0; i < textArray.length; i++) {
            let textStr = textArray[i];

            var replaced;
            if (textStr.startsWith('- ')) {
                replaced = '<ul><li>' + textStr.replace('- ', '') + '</li>';
                while (textArray[i + 1]?.startsWith('- ')) {
                    i++;
                    replaced += '<li>' + textArray[i].replace('- ', '') + '</li>';
                }
                replaced += '</ul>';
            } else if (textStr.startsWith('> ')) {
                replaced = '"' + textStr.replace('> ', '') + '"';
            } else {
                replaced = textStr;
            }
            replaced = replaced.replace(/_([^_]+)_/g, '<i>$1</i>');
            replaced = replaced.replace(/\*([^*]+)\*/g, '<b>$1</b>');
            replaced = replaced.replace(/~([^~]+)~/g, '<s>$1</s>');
            replaced = replaced.replace(/`([^`]+)`/g, '<code>$1</code>');
            newTextArray.push(replaced);
        }
        formattedText = newTextArray.join('<br />');
        return <div style={{ textAlign: 'left' }} key={Math.random()} dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    const handleShare = async () => {
        try {
            const uidReverse = user.uid.split('').reverse().join('');
            let url = `https://rpkr.in/#quote/${uidReverse}/${quoteKey}`;
            if (navigator.share) {
                await navigator.share({
                    title: 'RPKR.IN Quotes',
                    text: 'Check out this quotes!',
                    url: url,
                });
                console.log('Successfully shared');
            } else {
                navigator.clipboard.writeText(url);
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };
    const handleImageShare = async () => {
        setSharingImage(true);
        try {
            // Ensure that the component is fully rendered before attempting to capture the image
            await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust the delay time as needed

            // Capture the image from the entire QuoteCard component
            const canvas = await html2canvas(quoteCardRef.current);
            const imageUrl = canvas.toDataURL('image/png');

            // Convert the base64 image to a File object
            const blob = await (await fetch(imageUrl)).blob();
            const file = new File([blob], 'quoteCardImage.png', { type: blob.type });

            // Use Web Share API to share the image
            if (navigator.canShare && navigator.canShare({ files: [file] })) {
                await navigator.share({
                    title: 'RPKR.IN Quotes',
                    text: 'Check out this quote!',
                    files: [file],
                });
                console.log('Successfully shared');
            } else {
                // Fallback to clipboard if Web Share API v2 is not supported
                navigator.clipboard.writeText(imageUrl);
            }
        } catch (error) {
            console.error('Error sharing image:', error.message);
        } finally {
            setSharingImage(false);
        }
    };

    return (
        <Badge color="warning" badgeContent={(quotesDB.quotes[quoteKey]?.uploaded == false) ? " " : null} sx={{ margin: 2 }} key={quoteKey}>
            <Card ref={quoteCardRef} sx={{ width: '90vw', maxWidth: '350px', padding: '5px', borderRadius: 1 }}>
                <CardContent >

                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {editMode ?
                        <>
                            <TextField
                                multiline
                                margin="normal"
                                label="Quote"
                                id="outlined-required"
                                required
                                fullWidth
                                value={quote}
                                onChange={(e) => setQuote(e.target.value)}
                                autoComplete="off"
                            />
                            <FormControlLabel control={
                                <Checkbox
                                    checked={saveVersion}
                                    onChange={(e) => setSaveVersion(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} label="Save as new version" />
                            <Autocomplete
                                value={themes}
                                onChange={(e, selectedObject) => {
                                    if (selectedObject !== null) {
                                        setThemes(selectedObject);
                                    }
                                }}
                                multiple
                                fullWidth
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={options}
                                noOptionsText="Press enter to add new theme"
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            value={textValue}
                                            onChange={handleOptionType}
                                            onKeyDown={handleOptionChange}
                                            label="Themes"
                                            placeholder="Add Themes"
                                        />
                                    );
                                }}
                            />
                            <br />
                        </>
                        :
                        <>
                            {formattedText(quote)}
                            <br />
                            {themes.map((theme, index) => (
                                <Chip sx={{
                                    margin: 1,
                                }} label={theme} key={index} />
                            ))}

                        </>}
                    <br />
                    <div>
                        {editMode === false ? (
                            sharingImage ?
                                <>
                                    <Tooltip title="Liked">
                                        <Button
                                            sx={{
                                                borderRadius: 6,
                                            }}
                                            onClick={handleLike}
                                            color="error">
                                            <FavoriteIcon />
                                            <span>{quotesDB.quotes[quoteKey]?.likes}</span>
                                        </Button>
                                    </Tooltip>
                                </>
                                :
                                <>
                                    <Tooltip title={likeDone ? "Liked" : "Like"}>
                                        <Button
                                            sx={{
                                                borderRadius: 6,
                                            }}
                                            onClick={handleLike}
                                            color="error">
                                            {likeDone ? <FavoriteIcon /> : <FavoriteBorderIcon />}<span>{quotesDB.quotes[quoteKey]?.likes}</span>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Share Image">
                                        <IconButton
                                            onClick={handleImageShare}
                                        >
                                            <ImageIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Share Link">
                                        <IconButton
                                            onClick={handleShare}
                                        >
                                            <ShareIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="More">
                                        <IconButton
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>

                                </>)
                            :
                            <>
                                <IconButton onClick={handleSubmit} disabled={disabled}>
                                    <CheckCircleIcon />
                                </IconButton>
                                <IconButton onClick={handleCancelEdit} disabled={disabled}>
                                    <CancelIcon />
                                </IconButton>

                            </>
                        }
                    </div>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {(editMode === false && (!(quotesDB.quotes[quoteKey]?.isDeleted)) && (!(quotesDB.quotes[quoteKey]?.isArchived))) &&
                            <MenuItem onClick={handleEditMode}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                Edit
                            </MenuItem>
                        } {(editMode === false && (!(quotesDB.quotes[quoteKey]?.isDeleted)) && (!(quotesDB.quotes[quoteKey]?.isArchived))) &&
                            <Divider />
                        }
                        {(!(quotesDB.quotes[quoteKey]?.isDeleted)) && (
                            quotesDB.quotes[quoteKey]?.isArchived ?
                                <MenuItem onClick={handleUnArchive}>
                                    <ListItemIcon>
                                        <UnarchiveIcon fontSize="small" />
                                    </ListItemIcon>
                                    UnArchive
                                </MenuItem> :
                                <MenuItem onClick={handleArchive}>
                                    <ListItemIcon>
                                        <ArchiveIcon fontSize="small" />
                                    </ListItemIcon>
                                    Archive
                                </MenuItem>
                        )
                        }

                        {(!(quotesDB.quotes[quoteKey]?.isArchived)) &&
                            (quotesDB.quotes[quoteKey]?.isDeleted ?
                                <MenuItem onClick={handleRestore}>
                                    <ListItemIcon>
                                        <RestoreIcon fontSize="small" />
                                    </ListItemIcon>
                                    Restore
                                </MenuItem> :
                                <MenuItem onClick={handleDelete}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    Delete
                                </MenuItem>
                            )
                        }
                    </Menu>
                </CardContent>
            </Card>
        </Badge >
    );
}

const QuotesList = () => {
    const user = useSelector((state) => state.user);
    const quotesDB = useSelector((state) => state.quotes)?.[user.uid] || {
        quotes: {},
        themes: [],
    };

    const [filterThemes, setFilterThemes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [sortAnchorEl, setSortAnchorEl] = useState('desc: likes');
    const [showArchived, setShowArchived] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);
    const [allThemes, setAllThemes] = useState(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleArchived = () => {
        setShowArchived(!showArchived);
    };

    const handleToggleDeleted = () => {
        setShowDeleted(!showDeleted);
    };

    const handleToggleAllThemes = () => {
        setAllThemes(!allThemes);
    };

    const filteredQuotes = Object.keys(quotesDB.quotes)
        .filter((quoteKey) => {
            const quote = quotesDB.quotes[quoteKey];
            const matchesThemes =
                filterThemes.length === 0 ||
                (allThemes
                    ? filterThemes.every((theme) => quote.themes.includes(theme))
                    : filterThemes.some((theme) => quote.themes.includes(theme)));
            const matchesSearch =
                searchText === '' ||
                quote.quote.some((quoteText) =>
                    quoteText.toLowerCase().includes(searchText.toLowerCase())
                );
            const isArchived = quote.isArchived && !showArchived;
            const isDeleted = quote.isDeleted && !showDeleted;

            return matchesThemes && matchesSearch && !isArchived && !isDeleted;
        })
        .sort((a, b) => {
            const [sortType, sortField] = sortAnchorEl?.split(':') || [];
            if (sortType === 'asc') {
                if (sortField === 'time') {
                    return a - b;
                } else if (sortField === 'likes') {
                    return (
                        quotesDB.quotes[a].likes - quotesDB.quotes[b].likes || a - b
                    );
                }
            } else if (sortType === 'desc') {
                if (sortField === 'time') {
                    return b - a;
                } else if (sortField === 'likes') {
                    return (
                        quotesDB.quotes[b].likes - quotesDB.quotes[a].likes || b - a
                    );
                }
            }
            return 0;
        });

    return (
        <div style={{ margin: '15px 0px', height: 'fit-content' }}>
            <>
                <Card sx={{
                    width: '90vw',
                    padding: 1,
                    margin: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    height: 'fit-content',
                    alignContent: 'center',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    '@media (max-width: 900px)': {
                        flexWrap: 'wrap'
                    },
                }}>
                    <Autocomplete
                        limitTags={2}
                        id="multiple-limit-tags"
                        multiple
                        fullWidth
                        options={quotesDB.themes}
                        getOptionLabel={(option) => option}
                        value={filterThemes}
                        onChange={(e, selectedThemes) => setFilterThemes(selectedThemes)}
                        renderInput={(params) => (
                            <TextField {...params} label="Filter by Themes" placeholder="" />
                        )}
                        sx={{ minWidth: 300, margin: 1 }}
                    />
                    <TextField
                        label="Search"
                        fullWidth
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{
                            margin: 1, '@media (max-width: 900px)': {
                                width: '80%'
                            },
                        }}
                    />
                    <Tooltip title="Sort">
                        <IconButton onClick={handleClick} >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </Card>

                <div >
                    {filteredQuotes.map((quoteKey) => (
                        <QuoteCard quoteKey={quoteKey} key={quoteKey} />
                    ))}
                    {filteredQuotes.length < 1 && <h3>No Matching Quotes</h3>}
                </div>
            </>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setSortAnchorEl('asc:time')}>
                    Sort by Time Ascending
                </MenuItem>
                <MenuItem onClick={() => setSortAnchorEl('desc:time')}>
                    Sort by Time Descending
                </MenuItem>
                <MenuItem onClick={() => setSortAnchorEl('desc:likes')}>
                    Sort by more likes
                </MenuItem>
                <MenuItem onClick={() => setSortAnchorEl('asc:likes')}>
                    Sort by less likes
                </MenuItem>
                <Divider />
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showArchived}
                                onChange={handleToggleArchived}
                            />
                        }
                        label="Show Archived"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showDeleted}
                                onChange={handleToggleDeleted}
                            />
                        }
                        label="Show Deleted"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allThemes}
                                onChange={handleToggleAllThemes}
                            />
                        }
                        label="Match All Themes"
                    />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default function QuotePage() {
    const [uploadData, isUploading, uploadError] = useQuoteUpdateFirebase();
    const [CreateModal, setCreateModal] = useState(false);

    const handleClose = () => {
        setCreateModal(false);
    };

    const handleUpdate = () => {
        uploadData();
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };
    return (
        <main>
            <Helmet>
                <title>Quotes</title>
            </Helmet>
            {uploadError && <Alert severity="error">{uploadError.message}</Alert>}
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 80, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction
                    key="Add"
                    icon={<AddIcon />}
                    tooltipTitle="Add Quote"
                    onClick={() => setCreateModal(true)}
                />
                <SpeedDialAction
                    key="Sync"
                    icon={<SyncIcon />}
                    tooltipTitle="Sync Server"
                    onClick={handleUpdate}
                />
            </SpeedDial>
            <Modal
                open={CreateModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >

                <div style={{ ...style }}>
                    <QuoteForm onClose={handleClose} />
                </div>
            </Modal>
            <QuotesList />
        </main>
    );
}
