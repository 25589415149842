import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {doc, getDoc} from "firebase/firestore";
import {db} from '../data/firebase';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';


import {Accordion} from '../component/components';
import frontProfile from '../files/frontProfile.jpg';
import backProfile from '../files/backProfile.jpg';
import GDSCCer from '../files/GDSCLead.jpg';
import IBMCer from '../files/IBM.jpg';
import FLYCer from '../files/FLY.jpg';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function Portfolio() {
    const {shortName} = useParams();
    const [gotData, setGotData] = useState(false);
    const [redirectLink, setRedirectLink] = useState(null);
    const [password, setPassword] = useState();
    const [entryPassword, setEntryPassword] = useState('');
    const [error, setError] = useState(null);
    const [uploadDoc, setUploadDoc] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const uploadData = async () => {
        try {
            const shortLinkDocRef = doc(db, 'shortlink', 'data');
            const shortLinkMasterDoc = await getDoc(shortLinkDocRef);
            const shortLinkMasterDocData = shortLinkMasterDoc.data();
            setUploadDoc(shortLinkMasterDocData);
        } catch (err) {
            console.error(err);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        uploadData();
    }, []);
    useEffect(() => {
        if (uploadDoc !== null && redirectLink === null) {
            let shortLinkData = {};
            const {arrayShortLinks, fireStoreTime, serverArrayLinks, ...uidLinks} = uploadDoc;
            for (let uid in uidLinks) {
                shortLinkData = {...shortLinkData, ...uidLinks[uid]};
            }
            if (shortLinkData[shortName] !== undefined) {
                if (shortLinkData[shortName]?.password?.trim() == null) {
                    window.location.replace(shortLinkData[shortName].link);
                } else {
                    setRedirectLink(shortLinkData[shortName].link);
                    setPassword(shortLinkData[shortName].password);
                    setGotData(true);
                }

            }
        }
    }, [uploadDoc]);
    const handlePasswordChange = (e) => {
        setEntryPassword(e.target.value);
        if (e.target.value === password) {
            setError(null);
            window.location.replace(redirectLink);
        } else {
            if (e.target.value.length === password.length)
                setError("Wrong Password");
        }
    }

    const handleClose = () => {
        setGotData(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: 3,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        textAlign: 'center',
        maxWidth: '90%',
    };
    return (
        <>
            <Modal
                open={gotData}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >

                <Box sx={{...style, width: 400}}>
                    <h4>Enter password for short link: </h4>
                    <TextField
                        margin="normal"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={entryPassword}
                        onChange={handlePasswordChange}
                        autoComplete="off"
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                </Box>
            </Modal>
            <div className="port-contain">
                <ProfileLeftDisplay/>
                <ProfileRightDisplay/>
            </div>

            <Footer/>
        </>
    );
};

function ProfileLeftDisplay() {
    return (
        <>
            <div className="port-box1">
                <div className="flex-row">
                    <div className="port-photo">
                        <div className="face-inner">
                            <div className="front-face">
                                <div className="flex-row"><img src={frontProfile} alt="Pranay Kumar Reddy Ravula"/>
                                </div>
                            </div>
                            <div className="back-face">
                                <div className="flex-row"><img src={backProfile}
                                                               alt="Pranay Kumar Reddy Ravula - Childhood"/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex-col">
                        <div className="name-box">
                            <div className="title">
                                <span className="block"></span>
                                <h1 className="cpink">Pranay Kumar Reddy<span></span></h1>
                            </div>
                            <div className="role">
                                <span className="block"></span>
                                <div className="flex.row"></div>
                                <h2 className="cwhite1">Web Developer<span></span>
                                </h2>
                            </div>
                        </div>
                    </div>

                </div>

                <br/>
                <div className="flex-col">
                    <a href="https://mailto:rpranayreddy40@gmail.com/" title="Email of Pranay Kumar Reddy">
                        <p className="content-p3 cwhite1 poicur">
                            <EmailIcon/>
                            &nbsp;rpranayreddy40@gmail.com
                        </p>
                    </a>
                    <a href="https://wa.me/917680904588" title="Whatsapp & Calls of Pranay Kumar Reddy">
                        <p className="content-p3 cwhite1 poicur">
                            <CallIcon/>
                            &nbsp;+91 768 090 4589</p>
                    </a>
                </div>
                <br/><br/>
                <div className="flex-row ">
                    <a href="http://www.linkedin.com/in/rpkr" title="Linkedin page of Pranay Kumar Reddy"><span
                        className="social-sym hblue">
                        <LinkedInIcon/>
                    </span></a>
                    <a href="https://github.com/rpranaykumarreddy" title="Github page of Pranay Kumar Reddy"><span
                        className=" social-sym hblue ">
                        <GitHubIcon/></span></a>
                    <a href="https://twitter.com/rpkr_in" title="Twitter page of Pranay Kumar Reddy"><span
                        className=" social-sym hblue">
                        <TwitterIcon/>
                    </span></a>
                </div>
                <br/>
                <div className="skills">
                    <p className="content-p1 tebold cblue">Technical Skills</p>
                    <div className="badge-head">
                        <p style={{float: "left"}} className="content-p2 vishid cblue"><i
                            className="fa-solid fa-circle-info"></i></p>
                        <p className="content-p2 disinbl tebold cpink">Proficient</p>
                    </div>
                    <div className="badge-list">
                        <p className="badge">Spring-Boot</p>
                        <p className="badge">ReactJS</p>
                        <p className="badge">Java</p>
                        <p className="badge">JavaScript</p>
                        <p className="badge">HTML & CSS</p>
                        <p className="badge">Firebase</p>
                        <p className="badge">Electron JS</p>
                        <p className="badge">MySQL</p>
                        <p className="badge">Mongo DB</p>
                        <p className="badge">REST</p>
                        <p className="badge">ReduxJS</p>
                        <p className="badge">Git</p>
                        <p className="badge">C++</p>
                        <p className="badge">Data Structures</p>
                    </div>
                    <div className="badge-head">

                        <p style={{float: "left"}} className="content-p2 vishid cblue"><i
                            className="fa-solid fa-circle-info"></i></p>
                        <p className="content-p2 disinbl tebold cpink">Learning</p>
                    </div>
                    <div className="badge-list">
                        <p className="badge">Kafka</p>
                        <p className="badge">Linux</p>
                        <p className="badge">Microservices</p>
                    </div>
                </div>
                <div className="skills">
                    <p className="content-p2 tebold cblue">Soft Skills</p>
                    <br/><br/>
                    <div className="badge-list">
                        <p className="badge">Problem Solving</p>
                        <p className="badge">Leadership</p>
                        <p className="badge">Communication</p>
                        <p className="badge">Cross-functional Collaboration</p>
                        <p className="badge">Creativity</p>
                    </div>
                </div>
            </div>
        </>
    )
};

function ProfileRightDisplay() {
    return (
        <>

            <div className="port-box2">
                <ProfileAboutMeDisplay/>
                <EducationDisplay/>
                <ExperienceDisplay/>
                <ProjectsDisplay/>
                <CertificateDisplay/>
                <HobbiesDisplay/>
                <FormDisplay/>
            </div>
        </>
    )
};

function ProfileAboutMeDisplay() {
    return (
        <>
            <div>
                <h1 className="content-h6 pinkbold">About</h1>
                <p className="content-p3 cwhite2 tejustify">
                    <b>Ravula Pranay Kumar Reddy (RPKR)</b>
                    <br/><br/>
                    As a full stack developer with a passion for innovation, I
                    specialize in both <span className="pinkbold">Spring Boot</span> for backend services and <span className="pinkbold">React</span> for dynamic, user-friendly interfaces.
                    <i className="cblue"> During my freelancing phase</i>, I’ve also gained experience with the <span
                    className="pinkbold">MERN</span> stack
                    and <span className="pinkbold">Django</span>, enabling me to deliver end-to-end solutions for various projects.
                    <br/><br/>
                    <i className="cblue">My current focus is on contributing to the development of <span className="pinkbold">robust, scalable products</span>.</i> I
                    continuously refine my skills and stay ahead of emerging technologies like <span
                    className="pinkbold">AI</span>. I’m also
                    expanding my knowledge of <span className="pinkbold">DevOps concepts</span> to streamline workflows, automate processes, and actively contribute to DevOps discussions.
                </p>
                <br/>
                <div className="flex-row">
                    <p className="tebold content-p3 cwhite1 tecenter">
                        "My coding journey begins at 10 AM and wraps up enthusiastically after 2 AM."
                    </p>
                </div>
            </div>
            <br/>
        </>
    )
};

function EducationDisplay() {
    const [educationState, setEducation] = useState([
        {
            title: "B.Tech",
            branch: "Computer Science and Engineering",
            year: "2024",
            institute: "Indian Institute of Information Technology (IIIT) Bhopal"
        },
        {title: "Intermediate (TSBIE)", branch: "MPC", year: "March 2020", institute: "Nano Junior College"},
        {title: "Schooling (CBSE)", branch: "AISSE", year: "April 2018", institute: "Academic Heights School"}
    ]);

    const StudyDetails = ({title, branch, year, institute}) => (
        <div className="study">
            <h1 className="content-p3 pinkbold teupper" style={{paddingBottom:"3px", display:"block"}}>{title} | {branch} | {year}</h1>
            <h1 className="content-p3 tebold cwhite2">{institute}</h1>
        </div>
    );

    return (
        <>
            <Accordion className="tebold cblue content-p2" name="Education">
                {educationState.map((education, index) => (
                    <StudyDetails key={index} title={education.title} branch={education.branch} year={education.year}
                                  institute={education.institute}/>
                ))}
            </Accordion>
            <br/>
        </>
    )
};

function ExperienceDisplay() {
    const [experienceState, setExperience] = useState([
        {
            title: "Associate SDE | NexTuple Inc",
            timeline: "Jan 2024 – Present",
            achievements: ["Collaborating with senior engineers to continuously develop new features, resolve bugs and ensure seamless integration between microservices.",
                "Enhancing product quality by understanding microservices architecture, effectively troubleshooting issues"]
        },
        {
            title: "SDE Intern | Quantify Capital",
            timeline: "Jul 2023 – Nov 2023",
            achievements: ["Developed an in-house learning management system that enhanced user engagement by 30% compared to the previous third-party solution, leading to improved user satisfaction and adoption."]
        },
        {
            title: "SDE Intern | TechShine Solutions",
            timeline: "May 2023 – Jul 2023",
            achievements: ["Spearheaded the development of a desktop application streamlining online resource management, reducing manual data entry by 25%."]
        }
    ]);
    const ExperienceDetails = ({timeline, achievements}) => (
        <div className="matter-main">
            {timeline && <><h1 className="content-p3 cblue">{timeline}</h1><br/></>}
            {achievements && <>
                <ul className="list cwhite1">
                    {achievements.map((achivement, index) => (
                        <li key={index}>{achivement}</li>
                    ))}
                </ul>
                <br/></>}

        </div>
    );
    return (
        <>
            <h1 className="content-h6 pinkbold">Experience</h1>
            <div className="matter-main">
                <div className="matter-projects">
                    <br/>
                    {
                        experienceState.map((experience, index) => (
                            <Accordion key={index} className=" content-p2" name={experience.title}>
                                <ExperienceDetails timeline={experience.timeline}
                                                   achievements={experience.achievements}/>
                            </Accordion>
                        ))
                    }

                </div>
            </div>
            <br/><br/>
        </>
    )
};

function ProjectsDisplay() {
    const [projectsState, setProjects] = useState([
        {
            title: "Freelancing (Desktop Application) | Cloth Store Management System",
            role: "Developer",
            teamSize: "1 person (me)",
            duration: "1 month (July 2023)",
            description: "The project involved creating and launching a desktop application with a user-friendly interface for a renowned clothing store. It included cost-saving features resulting in annual savings and improved financial transparency through the implementation of company ledger protocols.",
            accomplishments: [
                "Achieved a 5% boost in sales revenue within the first two months.",
                "Automated cost-saving functions resulting in annual cost reduction of more than ₹1,00,000.",
                "Streamlined financial audit processes, reducing errors by 20%."
            ],
            date: "June 2024"
        },
        {
            title: "Freelancing | House of Dreams",
            role: "Developer",
            teamSize: "1 person (me)",
            duration: "2 days",
            description: "This website is made as service to a startup. We made a similar card reveal as apple wallet.",
            accomplishments: [],
            date: "March 20, 2023"
        },
        {
            title: "Chrome Extension | Our-Env",
            role: "Lead Developer",
            teamSize: "2 people",
            duration: "2 months",
            description: "The Chrome Extension for Professionals and Students is a user-friendly tool with a sidebar for quick access to websites and a dashboard with various productivity features.",
            accomplishments: [
                "*Simplified multitasking* with customizable workspace groups and shortcuts.",
                "Enhanced user experience with a visually appealing *grid-inspired interface*.",
                "Positioned the system for future scalability and adaptation to emerging user needs through modular design."
            ],
            link: "https://github.com/rpranaykumarreddy/our-env/",
            linkText: "Github Link",
            date: "April 19, 2023"
        },
        {
            title: "Web App | Resume Maker",
            role: "Developer",
            teamSize: "1 person (me)",
            duration: "7 days",
            description: "This helps in creating Resume in a structured manner",
            accomplishments: [
                "*Storing resume data* for future purpose via multiple ways"
            ],
            link: "https://gdsc-iiit-bhopal.github.io/resume-maker/",
            linkText: "Demo",
            date: "November 22, 2022"
        },
        {
            title: "Chrome Extension | YouTube Playlist Ranger",
            link: "https://rpranaykumarreddy.github.io/youtube-Ext/",
            linkText: "Github Link",
            date: "October 22, 2022",
            role: "Developer",
            teamSize: "1 person (me)",
            duration: "4 days",
            description: "This Extension calculates the time taken to watch a range of videos in a youtube playlist along with different modes.",
            accomplishments: [
                "Understanding the *architecture of Chrome Extensions & version of product*"
            ]
        },
        {
            title: "Web App | Bulk Certificate Maker & Verifier",
            link: "https://rpranaykumarreddy.github.io/bulk-certificate/",
            linkText: "Github Link",
            date: "September 23, 2022",
            role: "Developer",
            teamSize: "1 person (me)",
            duration: "2 days",
            description: "This Web App is to create PDF in Bulk with customization along with a feature of verification.",
            accomplishments: [
                "*Maker:* Creates pdfs based on data (object).",
                "*Verifer:* Scan the QR & get full data & Integration of attendance API (optional)."
            ]
        },
        {
            title: "Webpage | Wedding Invitation & Moments Page (all-in-one)",
            link: "https://github.com/rpranaykumarreddy/wedding",
            linkText: "Github Link",
            date: "May 23, 2022",
            role: "Team Leader & Lead Developer",
            teamSize: "2 people",
            duration: "2 days",
            description: "This website is to send Invitation to different with same link. but, dynamic to them with the help of URL Params. Serves as a moment page.",
            accomplishments: []
        },
        {
            title: "AI Project | Detection of Breast Cancer",
            link: "https://github.com/rpranaykumarreddy/AI-breast-cancer",
            linkText: "Github Link",
            date: "February 23, 2022",
            role: "Team Leader & Lead Developer",
            teamSize: "7 people",
            duration: "21 days",
            description: "This website interfaced project is a small step to assist cancer patients. But, It got me an opportunity to explore AI, work on back-end & lead a hands-on AI project.",
            accomplishments: [
                "Handling *a mid-size team* in an inexperienced field.",
                "Getting *high accurate predictions* for the Health sector.",
                "*Dynamic predictions* based on datasets accuracy."
            ]
        },
        {
            title: "Hackthon | UPSIDA",
            link: "https://github.com/rpranaykumarreddy/upsida-demo",
            linkText: "Github Link",
            date: "August 2, 2021",
            role: "Designer & Lead Web Developer",
            teamSize: "3 people",
            duration: "21 days",
            description: "This website is for Uttar Pradesh State Industrial Development Authority Hackathon.",
            accomplishments: [
                "Got to know *vision of government organization*",
                "*Animated (PPT) slide show*.",
                "Parallax *split screen*.",
                "Smooth & *Uniform Design*.",
                "Fully responsive web pages."
            ]
        },
        {
            title: "Website | We thinc Solutions",
            link: "https://wethinc.in",
            linkText: "wethinc.in",
            date: "April, 2021",
            role: "Designer & Lead Web Developer",
            teamSize: "3 people",
            duration: "10 days",
            description: "This website is for our freelancing service. It includes numerous features to prove our potential.",
            accomplishments: [
                "*AJAX* query for *dynamic* web page.",
                "In-built *Contact Form*.",
                "Fun with *animations*.",
                "Hover and click actions.",
                "Fully responsive web pages."
            ]
        },
        {
            title: "Freelancing | Sri Sai Ganesh Steel & Cement Traders",
            date: "May, 2021",
            role: "Designer & Lead Web Developer",
            teamSize: "3 people",
            duration: "2 days",
            description: "This website is for a small business. It is a simple & fully responsive website.",
            accomplishments: [
                "Fully responsive web pages.",
                "Simple & easy to understand."
            ]
        },
        {
            title: "Freelancing | The NightCap Restaurant",
            date: "May, 2021",
            role: "Designer & Lead Web Developer",
            teamSize: "3 people",
            duration: "5 days",
            description: "This website is for a small restaurant. The target audience is both the at-place & at-home customers.",
            accomplishments: [
                "Menu *filtering functionality*.",
                "*Parallax* photo division.",
                "Animated logo-home page.",
                "Fully responsive web pages."
            ]
        },
        {
            title: "Freelancing | Hyderabad Arts Academy",
            date: "February, 2021",
            role: "Designer & Lead Web Developer",
            teamSize: "3 people",
            duration: "10 days",
            description: "This website is our first website. It is simple & fully responsive website.",
            accomplishments: []
        }


    ]);
    const ProjectDetails = ({
                                index,
                                title,
                                role,
                                teamSize,
                                duration,
                                description,
                                accomplishments,
                                link,
                                linkText,
                                date
                            }) => {

        const highlight = (text) => {
            let textArray = text.split("*");
            let highlightedText = [];
            for (let i = 0; i < textArray.length; i++) {
                if (i % 2 === 0) {
                    highlightedText.push(textArray[i]);
                } else {
                    highlightedText.push(<span className="bluebold" key={i}>{textArray[i]}</span>);
                }
            }
            return highlightedText;
        }
        return (

            <div className="matter-projects" key={index}>
                <br/>
                <Accordion className="content-p2" name={title}>
                    <div className="matter-main">
                        {linkText && <> <a href={link} target="_blank" rel="noreferrer"><span
                            className="content-p2 cpink">{linkText}</span></a>
                            <span className="content-p3 cwhite1"> &#160; &#160; | &#160; &#160;</span></>} <span  className="content-p3 cwhite1"> {date}</span><br/>
                        {role && <><p className="content-p2 bluebold">{role}</p><br/></>}
                        {(teamSize || duration) && <><p className="content-p4 cwhite2">{teamSize && <>Team
                            Size: {teamSize} &#160;|</>}{duration && <> &#160;Duration: {duration}</>}</p><br/></>}
                        {description && <><p className="content-p3 cwhite2">{highlight(description)}</p><br/></>}
                        {accomplishments.length > 0 && <><p className="content-p2 cwhite1">Accomplishments</p>
                            <ul className="list cwhite1">
                                {accomplishments.map((accomplishment, index) => (
                                    <li key={accomplishment}>{highlight(accomplishment)}</li>
                                ))}
                            </ul>
                        </>}
                    </div>
                </Accordion>
            </div>
        )
    };
    return (
        <>
            <h1 className="content-h6 pinkbold">Projects</h1>
            <div className="matter-main">
                {
                    projectsState.map((project, index) => (
                        <ProjectDetails key={index} index={index} title={project.title} role={project.role}
                                        teamSize={project.teamSize} duration={project.duration}
                                        description={project.description} accomplishments={project.accomplishments}
                                        date={project.date} link={project.link} linkText={project.linkText}/>
                    ))
                }
            </div>
            <br/><br/>
        </>
    )
};

function CertificateDisplay() {
    const [certificateState, setCertificate] = useState([
        {
            title: 'Certificate of Completion "GDSC Lead" | Google Developer Students Clubs',
            date: "July, 2023",
            description: "Developed leadership and project management skills through leading GDSC.",
            duration: "1 year",
            imgSrc: GDSCCer,
            imgAlt: "Certificate of Completion (2022-23 Google Developer Student Club(GDSC) Lead)"
        },
        {
            title: "Certificate for 2nd position in IBM Innovation Camp: AI | IBM & Edunet",
            date: "February, 2021",
            description: "Acquired in-depth knowledge and practical experience in Artificial Intelligence.",
            duration: "75 days",
            imgSrc: IBMCer,
            imgAlt: "Certificate for securing second position in IBM Innovation Camp: AI"
        },
        {
            title: 'Certificate of "FLY-Scholar" | CMI',
            date: "August, 2021",
            description: "Developed strong leadership skills and gained valuable experience in leading teams",
            duration: "26 days",
            imgSrc: FLYCer,
            imgAlt: 'Certificate of "Finding The Leader in You - Scholar" program'
        }
    ]);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const closePopup = () => {
        setSelectedCertificate(null);
    };
    const CertificateDetails = ({title, date, description, duration, index}) => {
        return (
            <>
                <Accordion className="content-p2" name={title}>
                    <div className="matter-main">
                        <span className="content-p2 cpink poicur" onClick={() => {
                            setSelectedCertificate(index)
                        }}>Click to view</span>
                        <span className="content-p3 cwhite1">&#160;&#160; | &#160;&#160; {date}</span>
                        <br/>
                        <p className="content-p2 bluebold">{description}</p><br/>
                        <p className="content-p4 cwhite2">Duration: {duration}</p>
                        <br/>
                    </div>
                </Accordion>
                <br/>
            </>
        );
    };
    return (
        <>
            {
                selectedCertificate !== null && (
                    <>
                        <div id="cer-img-pop">
                            <span id="cer-clo" onClick={closePopup}>&times;</span>
                            <div id="cer-dic-cen">
                                <img id="cer-img" src={certificateState[selectedCertificate].imgSrc}
                                     alt={certificateState[selectedCertificate].imgAlt}/>
                                <div id="cer-cap">{certificateState[selectedCertificate].imgAlt}</div>
                            </div>
                        </div>
                    </>
                )

            }

            <h1 className="content-h6 pinkbold">Certificates</h1>

            <div className="matter-main">
                {
                    certificateState.map((certificate, index) => (
                        <CertificateDetails key={index} title={certificate.title} date={certificate.date}
                                            description={certificate.description} duration={certificate.duration}
                                            index={index}/>
                    ))
                }
            </div>
            <br/>
        </>
    )
};

function HobbiesDisplay() {
    const [hobbiesState, setHobbies] = useState([
        "I enjoy reading and writing blogs, sharing thoughts on technology and life.",
        "I love exploring new ideas through creative writing and journaling.",
        "I'm guilty of watching films.",
        "I drink a lot of tea.",
        "I always walk in and out of worries got by trying new things.",
    ]);
    if (hobbiesState.length === 0) return (<></>);
    return (
        <div className="hobby">
            <h1 className="content-h6 pinkbold">Hobbies</h1>
            <ul className="list cwhite1">
                {hobbiesState.map((hobby, index) => (
                    <li key={index}>{hobby}</li>
                ))}
            </ul>
            <br/>
        </div>
    )
};

function FormDisplay() {
    /**
     *
     *  function validateform() {
     var name = document.contactform.name.value;
     var subject = document.contactform.subject.value;
     var mail = document.contactform.mail.value;
     var atposition = mail.indexOf("@");
     var dotposition = mail.lastIndexOf(".");

     if (name == null || name == " ") {
     alert("Name can't be blank");
     return false;
     } else if (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= x.length) {
     alert("Please enter a valid e-mail address");
     return false;
     } else if (subject == null || subject == "") {
     alert("Subject can't be blank");
     return false;
     }
     }
     */
    const validateform = (e) => {
        e.preventDefault();
        const name = document.contactform.name.value;
        const subject = document.contactform.subject.value;
        const mail = document.contactform.mail.value;
        const atposition = mail.indexOf("@");
        const dotposition = mail.lastIndexOf(".");

        if (name == null || name == " ") {
            alert("Name can't be blank");
            return false;
        } else if (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= mail.length) {
            alert("Please enter a valid e-mail address");
            return false;
        } else if (subject == null || subject == "") {
            alert("Subject can't be blank");
            return false;
        } else {
            document.contactform.submit();
        }
    }

    return (
        <>
            <h1 className="content-h5 cpink">Get me right below</h1><br/><br/>
            <div className="flex-col">
                <div className="port-form">
                    <form name="contactform" autoComplete="off" action="https://formsubmit.co/rpranayreddy40@gmail.com"
                          onSubmit={validateform} method="POST">
                        <input className="form" type="text" id="fname" name="name" placeholder="Name" required/>
                        <input className="form" type="mail" id="fmail" name="mail" placeholder="E-mail" required/>
                        <input className="form" type="text" id="fsubject" name="subject" placeholder="Subject"
                               required/>
                        <textarea className="form" id="fmessage" name="message" placeholder="Message"
                                  style={{height: '150px'}}></textarea>
                        <div className="button">
                            <div className="layer"></div>
                            <div className="botdot"></div>
                            <input type="submit" value="Submit"/>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

function Footer() {
    return (
        <>
            <footer>
                <div className="flex-col">
                    <h1 className="content-p3 cwhite1">
                        &copy; 2025 | Built with ❤️ by <a  style={{textDecoration: "underline"}} href="https://rpkr.in/">RPKR</a>
                    </h1>
                </div>
            </footer>

            <footer>
                <div className="flex-col">
                    <h1 className="content-p3 cwhite1">Powered By <a style={{textDecoration: "underline"}}
                                                                     href="https://wethinc.in/">We thinc Solutions</a>
                    </h1>
                </div>
            </footer>
        </>
    )
};