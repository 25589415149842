import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { auth } from "../../data/firebase";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";


import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';


export default function AccountPage(props) {
    return (
        <main>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <AccountInfo {...props} />
        </main>
    );
}

function AccountInfo(props) {
    const user = useSelector((state) => state.user);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorUsername, setErrorUsername] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);

    const validateForm = () => {
        let isValid = true;

        if (!username) {
            setErrorUsername("Email is required");
            isValid = false;
        } else {
            setErrorUsername(null);
        }

        if (!password) {
            setErrorPassword("Password is required");
            isValid = false;
        } else {
            setErrorPassword(null);
        }

        return isValid;
    };

    // const createAccount = async () => {
    //     setIsLoading(true);
    //     setErrorMessage(null);

    //     if (validateForm()) {
    //         createUserWithEmailAndPassword(auth, username, password)
    //             .then((userCredential) => {
    //                 const user = userCredential.user;
    //                 console.log("createauth User:", user);
    //                 setIsLoading(false);
    //             })
    //             .catch((error) => {
    //                 const errorCode = error.code;
    //                 if (errorCode === "auth/email-already-in-use") {
    //                     setErrorUsername("That email is already in use.");
    //                 } else if (errorCode === "auth/weak-password") {
    //                     setErrorPassword("That password is too weak.");
    //                 } else if (errorCode === "auth/invalid-email") {
    //                     setErrorUsername("That email isn't valid.");
    //                 } else if (errorCode === "auth/operation-not-allowed") {
    //                     setErrorMessage("Password log in hasn't been enabled.");
    //                 } else {
    //                     setErrorMessage("Something went wrong. Please try logging in again.");
    //                 }
    //                 setIsLoading(false);
    //             });
    //     } else {
    //         setIsLoading(false);
    //     }
    // };

    const signIn = async () => {
        setIsLoading(true);
        setErrorMessage(null);

        if (validateForm()) {
            signInWithEmailAndPassword(auth, username, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    setIsLoading(false);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    if (errorCode === "auth/user-disabled") {
                        setErrorMessage("That account has been disabled.");
                    } else if (errorCode === "auth/user-not-found") {
                        setErrorUsername("The email is not found.");
                    } else if (errorCode === "auth/invalid-credential") {
                        setErrorPassword("The password is incorrect.");
                    } else if (errorCode === "auth/invalid-email") {
                        setErrorUsername("That email isn't valid.");
                    } else {
                        setErrorMessage("Something went wrong.");
                    }
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const signOutin = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        const authInstance = getAuth();
        signOut(authInstance)
            .then(() => {
                console.log("signed out successfully");
                setIsLoading(false);
            })
            .catch((error) => {
                setErrorMessage("Something went wrong. Please try logging out again.");
                console.error(error);
                setIsLoading(false);
            });
    };

    let contents;
    if (user) {
        contents = (
            <>
                <p>Welcome {user.email ? user.email.split('@')[0] : "back"}!</p>
                <br />

                <Button variant="contained" onClick={signOutin} disabled={isLoading}>
                    {isLoading ? "Logging Out..." : "Log Out"}
                </Button>
            </>
        );
    } else {
        contents = (
            <>
                <form onSubmit={(e) => e.preventDefault()}>
                    <FormControl fullWidth margin="normal" error={!!errorUsername}>
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={username}
                            disabled={isLoading}
                            required
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <FormHelperText>{errorUsername}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth margin="normal" error={!!errorPassword}>
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            value={password}
                            disabled={isLoading}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormHelperText>{errorPassword}</FormHelperText>
                    </FormControl>

                    <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                        {/* <Button onClick={createAccount} disabled={isLoading}>
                            Create User
                        </Button> */}
                        <Button onClick={signIn} disabled={isLoading}>
                            Log In User
                        </Button>
                    </ButtonGroup>
                </form>
            </>
        );
    }

    return (
        <div>
            <Card sx={{ minWidth: 275, marginTop: '50px', maxWidth: 400, margin: 'auto', padding: '5px' }}>
                <CardContent>
                    <h2>Login or Create Account</h2>
                    <br />
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {contents}
                </CardContent>
            </Card>
        </div>
    );
}
